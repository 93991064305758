import React from 'react'
import { Link } from "gatsby"
import './Footer.scss'

const links = {
  Whatsapp: "https://wa.me/+79504184299",
  Intagram: "https://instagram.com/realelement_ru",
  Phone: "tel:+79029626179",
  Mail: "mailto:mail@realelement.ru",
  Telegram: "https://t.me/realelement_ru",
}

function Footer() {
  return (
    <div className="footer">
      <div className="footer__links">
        <h3>Наши услуги:</h3>
        <Link to="/outdoor/">Наружная реклама</Link>
        <Link to="/outdoor/">Дизайн и печать промо</Link>
      </div>
      <div className="footer__info">
        <p>ООО «Топ групп»</p>
        <p>ИНН 2465143647 ОРГН 1162468070160</p>
        <Link to="/policy/" target="_blank" rel="noreferrer">Политика конфиденциальности</Link>
        <p className="realart">Сайт создан командой <a href="https://heycoddes.ru/" target="_blank" rel="noreferrer">Hey!Coddes</a></p>
      </div>
      <div className="footer__studio">
        <h3>Ссылки для сотрудничества</h3>
        <a href={links.Mail} target="_blank" rel="noreferrer">mail@realelement.ru</a>
        <a href={links.Telegram} target="_blank" rel="noreferrer">Telegram</a>
      </div>
    </div>
  )
}

export default Footer
