import React from 'react'
import { Link } from "gatsby"

function ServiceLink(props) {
  return (
    <Link className="link" to={props.linkTo} activeClassName="active">
      <h3>{props.title}</h3>
      <p>{props.tags}</p>
    </Link>
  )
}

export default ServiceLink
