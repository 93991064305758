import React from 'react'

import Logo from './Logo'
import ServiceLink from './ServiceLink'
import Contact from './Contact'

import './Navigation.scss'

const tagsOutdoor = '#световые вывески #световые короба #объемные_буквы #панелькранштейны #интерьерные_вывески #рекламные_стеллы #брендирование_авто #бегущая_строка #выставочные_стенды #штендеры #хештеги #фотозоны'
const tagsDesign = '#свадебная_полиграфия #портреты_на_холсте #меню #афиша #логотипы #фирменные_стили'

function Navigation() {
  return (
    <nav className="navigation">
      <Logo />
      <ServiceLink title="Наружная реклама" tags={tagsOutdoor} linkTo="/outdoor/"/>
      <ServiceLink title="Дизайн и печать" tags={tagsDesign} linkTo="/design/" />
      <Contact />
    </nav>
  )
}

export default Navigation
