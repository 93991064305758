import React from 'react'
import { Link } from 'gatsby'

import LogoImg from '../../images/logo/white.svg'

function Logo() {
  return (
    <Link className="logoContainer" activeClassName="active" to="/">
      <div className="logo">
        <img src={LogoImg} alt="Логотип RealElement"/>
        <div className="logo__description">
          <h2>Мастерская<br />рекламы<br />и дизайна</h2>
        </div>
      </div>
    </Link>
  )
}

export default Logo
