import React, { useState } from 'react'
import { motion } from 'framer-motion'

import InstagramLogo from '../../images/social/instagram.svg'
import TelegramLogo from '../../images/social/telegram.svg'
import WhatsappLogo from '../../images/social/whatsapp.svg'
import MailLogo from '../../images/social/mail.svg'
import PhoneLogo from '../../images/social/phone-call.svg'

function Contact() {
  const [contactOpen, setContactOpen] = useState(false);

  const links = {
    Whatsapp: "https://wa.me/+79504184299",
    Intagram: "https://instagram.com/realelement_ru",
    Phone: "tel:+73919899330",
    Mail: "mailto:mail@realelement.ru",
    Telegram: "https://t.me/realelement_ru",
  }

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  }
  const container = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 12,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1
      }
    }
  };

  function handle() {
    setContactOpen(!contactOpen);
  }

  return (
    <>
      <div className="contactMobile">
        <a href="#contact" className={contactOpen ? "contactContainer open" : "contactContainer"} onClick={() => handle()}>
          <h3>Контакты</h3>
        </a>
        {contactOpen && (
          <motion.div
            variants={container}
            initial="hidden"
            animate="visible"
            className="contactLinks">
            <motion.a
              variants={item}
              href={links.Phone} className="phone">
              <img src={PhoneLogo} alt="Звонок" />
            </motion.a>
            <motion.a
              variants={item}
              href={links.Telegram} target="_blank" rel="noreferrer">
              <img src={TelegramLogo} alt="телеграм" />
            </motion.a>
            <motion.a
              variants={item}
              href={links.Whatsapp} target="_blank" rel="noreferrer">
              <img src={WhatsappLogo} alt="Вацап" />
            </motion.a>
            <motion.a
              variants={item}
              href={links.Mail} target="_blank" rel="noreferrer">
              <img src={MailLogo} alt="Почта" />
            </motion.a>
            <motion.a
              variants={item}
              href={links.Intagram} target="_blank" rel="noreferrer">
              <img src={InstagramLogo} alt="Инстаграм" />
            </motion.a>
          </motion.div>
        )}
      </div>
      <div className="contact">
        <h3>Контакты</h3>
        <div className="mobileLink">
          <a href={links.Phone} className="phone"><img src={PhoneLogo} alt="Звонок" />+7 391 989-93-30</a>
          <a href={links.Mail} className="mail" target="_blank" rel="noreferrer"><img src={MailLogo} alt="Почта" />mail@realelement.ru</a>
        </div>
        <div className="socialLink">
          <a href={links.Intagram} target="_blank" rel="noreferrer">
            <img src={InstagramLogo} alt="инстаграм" />
          </a>
          <a href={links.Telegram} target="_blank" rel="noreferrer">
            <img src={TelegramLogo} alt="телеграм" />
          </a>
          <a href={links.Whatsapp} target="_blank" rel="noreferrer">
            <img src={WhatsappLogo} alt="Вацап" />
          </a>
        </div>
      </div>
    </>
  )
}

export default Contact
